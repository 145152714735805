import { ValidationRenderer, RenderInstruction, ValidateResult } from 'aurelia-validation';
// import 'element-closest';

export class FormRendererNew {
  render(instruction: RenderInstruction) {
    for (let { result, elements } of instruction.unrender) {
      for (let element of elements) {
        this.remove(element, result);
      }
    }

    for (let { result, elements } of instruction.render) {
      for (let element of elements) {
        this.add(element, result);
      }
    }
  }

  add(element: Element, result: ValidateResult) {
    if (result.valid) {
      return;
    }

    const formGroup = element.closest('.form-group');
    if (!formGroup) {
      return;
    }

    // add the is-invalid class to the enclosing form-group div

    /* border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 */

    /* element.classList.remove(
      'border-gray-300',
      'focus:ring-indigo-500',
      'focus:border-indigo-500'
    ); */

    element.classList.add('is-invalid', 'aid-input--error');

    if (element.parentElement.classList.contains('input-group')) {
      element.parentElement.classList.add('is-invalid');
    }

    // add help-block
    /* const message = document.createElement('div');
    message.className = 'invalid-feedback';
    message.textContent = result.message;
    message.id = `validation-message-${result.id}`; */

    const message = document.createElement('p');
    message.classList.add('mt-2', 'text-sm', 'text-red-600');
    message.id = `validation-message-${result.id}`;
    message.innerText = result.message;

    formGroup.appendChild(message);
  }

  remove(element: Element, result: ValidateResult) {
    if (result.valid) {
      return;
    }

    const formGroup = element.closest('.form-group');
    if (!formGroup) {
      return;
    }

    /* element.classList.add(
      'border-gray-300',
      'focus:ring-indigo-500',
      'focus:border-indigo-500'
    ); */

    element.classList.remove('is-invalid', 'aid-input--error');

    // remove help-block
    const message = formGroup.querySelector(`#validation-message-${result.id}`);
    if (message) {
      formGroup.removeChild(message);

      // remove the is-invalid class from the enclosing form-group div
      if (formGroup.querySelectorAll('.invalid-feedback').length === 0) {
        element.classList.remove('is-invalid');
      }
    }
  }
}
