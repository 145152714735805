import { autoinject } from 'aurelia-framework';
import {
  ChangeProductPreviewRes,
  CreateSubscriptionDto,
  CreateSubscriptionRes,
  FinishSubscriptionRes,
  SuccessRes,
  UpdateAddressDto,
  UserCharge,
  UserSubscription,
} from 'models/api';
import { ApiService } from './api-service';

@autoinject
export default class SubscriptionService extends ApiService {
  constructor() {
    super('subscriptions');
  }

  getUserSubscriptions(): Promise<UserSubscription[]> {
    return super.get('');
  }

  getUserSubscriptionDetails(id: number): Promise<UserSubscription> {
    return super.get(`details/${id}`);
  }

  getUserSubscriptionCharges(id: number): Promise<UserCharge[]> {
    return super.get(`charges/${id}`);
  }

  async create(createSubscriptionDto: CreateSubscriptionDto): Promise<CreateSubscriptionRes> {
    return super.post('', createSubscriptionDto);
  }

  async finish(finishSubscriptionToken: string): Promise<FinishSubscriptionRes> {
    return super.post(`finish`, { finishSubscriptionToken });
  }

  async cancel(subscriptionId: number): Promise<SuccessRes> {
    return super.put(`${subscriptionId}/cancel`);
  }

  async changeProductPreview(
    subscriptionId: number,
    productId: number
  ): Promise<ChangeProductPreviewRes> {
    return super.put(`${subscriptionId}/change-product-preview/${productId}`);
  }

  async changeProduct(subscriptionId: number, productId: number): Promise<SuccessRes> {
    return super.put(`${subscriptionId}/change-product/${productId}`);
  }

  getNetsStatus(token: string): Promise<{ status: string }> {
    return super.get(`nets-status?finishSubscriptionToken=${token}`);
  }

  updateSubscriptionPayment(subscriptionId: number) {
    return super.get(`${subscriptionId}/update-payment`);
  }

  updateAddress(subscriptionId: number, dto: UpdateAddressDto) {
    return super.put(`${subscriptionId}/update-address`, dto);
  }

  pausePaperDelivery(subscriptionId: number, pausePaperDelivery: boolean) {
    return super.put(`${subscriptionId}/pause-paper-delivery`, { pausePaperDelivery });
  }
}
