import { PLATFORM } from 'aurelia-pal';
import { RouteConfig } from 'aurelia-router';

const LoginRoutes: RouteConfig[] = [
  {
    route: 'login',
    title: 'Logg inn',
    name: 'login',
    nav: true,
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/login/login') },
      header: { moduleId: null },
    },
    settings: {
      public: true,
    },
  },
  {
    route: 'login-client/:clientSlug',
    title: 'Logg inn med aboID',
    name: 'login-client',
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('views/login-client/login-client'),
      },
      header: { moduleId: null },
    },
    /*     settings: {
      public: true,
    }, */
  },
  {
    route: 'forgot-password',
    title: 'Glemt passord',
    name: 'forgot-password',
    nav: true,
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('views/forgot-password/forgot-password'),
      },
      header: { moduleId: null },
    },
    settings: {
      public: true,
    },
  },
  {
    route: 'reset-password',
    title: 'Endre passord',
    name: 'reset-password',
    nav: true,
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('views/reset-password/reset-password'),
      },
      header: { moduleId: null },
    },
    settings: {
      public: true,
    },
  },
  {
    route: 'callback',
    title: 'Videresender ...',
    name: 'login-callback',
    nav: true,
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('views/login-callback/login-callback'),
      },
      header: { moduleId: null },
    },
    settings: {
      public: true,
    },
  },
  {
    route: 'passwordless',
    title: 'Videresender ...',
    name: 'passwordless-callback',
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('views/login/passwordless-callback'),
      },
      header: { moduleId: null },
    },
    settings: {
      public: true,
    },
  },
];

const ProfileRoutes: RouteConfig[] = [
  {
    route: '/',
    title: 'Hjem',
    name: 'home',
    nav: true,
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('./views/home/home') },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },
  {
    route: 'profile',
    title: 'Min konto',
    name: 'profile',
    nav: true,
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('./views/profile/profile') },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },
  {
    route: 'privacy',
    title: 'Personvern og Cookies',
    name: 'privacy',
    nav: true,
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('./views/privacy/privacy') },
      header: { moduleId: null },
    },
    settings: {
      public: true,
    },
  },
  {
    route: 'subscriptions',
    title: 'Abonnementer',
    name: 'subscriptions',
    nav: true,
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('./views/subscriptions/subscriptions'),
      },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },

  {
    route: 'subscription/:id',
    title: 'Abonnement',
    name: 'subscription-details',
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          './views/subscription-details/subscription-details'
        ),
      },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },
  {
    route: 'subscriptions/cancelled',
    title: 'Avsluttede abonnementer',
    name: 'subscriptions-cancelled',
    nav: true,
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          './views/subscriptions/subscriptions-cancelled'
        ),
      },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },
  {
    route: 'home/accept-terms',
    title: 'Godta vilkår',
    name: 'accept-terms',
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('./views/accept-terms/accept-terms'),
      },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },

  {
    route: 'group/:id',
    title: 'Gruppe',
    name: 'group-details',
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('./views/group-details/group-details'),
      },
      header: {
        moduleId: PLATFORM.moduleName('components/header/main-header'),
      },
    },
  },
];

const SubscribeRoutes: RouteConfig[] = [
  {
    route: 'subscribe/:clientSlug',
    title: 'Kjøp abonnement',
    name: 'subscribe',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('./views/subscribe/subscribe') },
      header: { moduleId: null },
    },
  },
  {
    route: 'subscribe/finish',
    title: 'Fullfører',
    name: 'subscribe-finish',
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('./views/subscribe/subscribe-finish'),
      },
      header: { moduleId: null },
    },
    settings: {
      /*
        NOTE: This needs to be public because when on mobile, the Vipps may open the
        callback URL in a different browser than the user started with. Therefore this route
        can sometimes be opened in a browser where the user is not authenticated.
       */
      public: true,
    },
  },
];

export const AppRoutes: RouteConfig[] = [
  ...LoginRoutes,
  ...SubscribeRoutes,
  ...ProfileRoutes,
];
