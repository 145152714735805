import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { NotificationData, NotificationTypes } from 'components/notifications/notifications-area';

@autoinject
export class NotificationsService {
  constructor(private eventAggregator: EventAggregator) {}

  success(title: string, message: string, autoHide: number = 3500) {
    const notification: NotificationData = {
      title,
      message,
      notificationType: NotificationTypes.SUCCESS,
      autoHide,
    };
    this.eventAggregator.publish('notifications:create', notification);
  }

  error(title: string, message: string) {
    const notification: NotificationData = {
      title,
      message,
      notificationType: NotificationTypes.ERROR,
      closeButton: true,
      autoHide: 10000,
    };
    this.eventAggregator.publish('notifications:create', notification);
  }

  close(notificationId: string) {
    this.eventAggregator.publish('notifications:close', notificationId);
  }
}
