import { autoinject } from 'aurelia-framework';
import { addDays, getUnixTime } from 'date-fns';

@autoinject
export default class LocalService {
  constructor() {}

  async cache(key: string | number, value: any, session: boolean = false, expire?: number | null) {
    // Store in custom format
    const data = {
      expire: null,
      content: value,
    };

    // Set expire to 3 months by default
    if (!expire) {
      const date = addDays(new Date(), 7);
      data.expire = getUnixTime(date);
    } else {
      data.expire = expire;
    }

    // Remove expire when session by default
    if (session) {
      data.expire = null;
    }

    const dataString = JSON.stringify(data);

    if (session) {
      sessionStorage.setItem(key.toString(), dataString);
    } else {
      localStorage.setItem(key.toString(), dataString);
    }
  }

  async get<T = any>(key: string | number, session: boolean = false): Promise<T> {
    const data = session
      ? sessionStorage.getItem(key.toString())
      : localStorage.getItem(key.toString());
    if (!data) {
      return null;
    }

    try {
      const parsed = JSON.parse(data);
      const { expire, content } = parsed;

      // Check if cache has expired
      if (!session) {
        if (getUnixTime(new Date()) >= expire) {
          return null;
        }
      }

      return content as T;
    } catch (error) {
      console.error('Failed to get value from cache', data);
      return null;
    }
  }

  async remove(key: string | number, session: boolean = false) {
    if (session) {
      sessionStorage.removeItem(key.toString());
    } else {
      localStorage.removeItem(key.toString());
    }
  }

  async clear(session: boolean = false) {
    if (session) {
      sessionStorage.clear();
    } else {
      localStorage.clear();
    }
  }

  async getSession<T = any>(key: string | number): Promise<T> {
    return this.get<T>(key, true);
  }

  async set(key: string | number, value: any, session: boolean = false) {
    return this.cache(key, value, session, null);
  }

  async setSession(key: string | number, value: any) {
    return this.cache(key, value, true, null);
  }
}
