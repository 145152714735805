import { autoinject } from 'aurelia-framework';
import { ClientInfo } from 'models/api/models/ClientInfo';
import { ApiService } from './api-service';

@autoinject
export default class ClientService extends ApiService {
  constructor() {
    super('clients');
  }

  async getClientInfo(slug: string): Promise<ClientInfo> {
    return super.get(`info/${slug}`);
  }
}
