import { autoinject } from 'aurelia-framework';

export enum Animations {
  FADE_SCALE = 'fade_scale',
  FADE_SCALE_BLOCK = 'fade_scale_block',
}

interface AnimationFunction {
  enter: (element: HTMLElement) => void;
  leave: (element: HTMLElement) => void;
}

@autoinject
export class AnimationsService {
  constructor() {}

  protected fadeAndScale(): AnimationFunction {
    return {
      enter: (element: HTMLElement) => {
        element.classList.add('transform');
        element.classList.remove('opacity-0');
        element.classList.add('opacity-100');
        element.classList.remove('scale-95');
        element.classList.add('scale-100');
        element.classList.remove('ease-in');
        element.classList.add('ease-out');
        element.classList.remove('duration-75');
        element.classList.add('duration-100');
      },
      leave: (element: HTMLElement) => {
        element.classList.add('transform');
        element.classList.add('opacity-0');
        element.classList.remove('opacity-100');
        element.classList.add('scale-95');
        element.classList.remove('scale-100');
        element.classList.add('ease-in');
        element.classList.remove('ease-out');
        element.classList.add('duration-75');
        element.classList.remove('duration-100');
      },
    };
  }
  protected fadeAndScaleBlock(): AnimationFunction {
    const timeOut = setTimeout(function () {});
    return {
      enter: (element: HTMLElement) => {
        element.classList.add('transform');
        element.classList.remove('hidden');
        setTimeout(function () {
          element.classList.remove('opacity-0');
          element.classList.add('opacity-100');
          element.classList.remove('scale-95');
          element.classList.add('scale-100');
          element.classList.remove('ease-in');
          element.classList.add('ease-out');
          element.classList.remove('duration-75');
          element.classList.add('duration-300');
        }, 120);
      },
      leave: (element: HTMLElement) => {
        element.classList.add('transform');
        element.classList.add('opacity-0');
        element.classList.remove('opacity-100');
        element.classList.add('scale-95');
        element.classList.remove('scale-100');
        element.classList.add('ease-in');
        element.classList.remove('ease-out');
        element.classList.add('duration-75');
        element.classList.remove('duration-300');
        setTimeout(function () {
          element.classList.add('hidden');
        }, 300);
      },
    };
  }

  protected doAnimation(element: HTMLElement, state: boolean, animation: AnimationFunction) {
    if (state === true) {
      animation.enter(element);
    } else if (state === false) {
      animation.leave(element);
    }
  }

  animate(element: HTMLElement, state: boolean, animation: Animations) {
    switch (animation) {
      case Animations.FADE_SCALE:
        return this.doAnimation(element, state, this.fadeAndScale());
      case Animations.FADE_SCALE_BLOCK:
        return this.doAnimation(element, state, this.fadeAndScaleBlock());
      default:
        break;
    }
  }
}
