import { autoinject } from 'aurelia-framework';
import { Product } from 'models/api';
import { ApiService } from './api-service';

@autoinject
export default class ProductService extends ApiService {
  constructor() {
    super('products');
  }

  async getPublicProducts(clientSlug: string): Promise<Product[]> {
    return super.get(`${clientSlug}/public`);
  }

  async getUserProducts(clientSlug: string): Promise<Product[]> {
    return super.get(`${clientSlug}/user`);
  }

  async getPublicRelatedProducts(
    clientSlug: string,
    currentProductId: number
  ): Promise<Product[]> {
    return super.get(`${clientSlug}/related/${currentProductId}`);
  }
}
