import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import environment from '../../config/environment.json';

export class ApiService {
  protected httpClient: AxiosInstance;

  constructor(endpoint: string) {
    const instance = axios.create({
      baseURL: `${environment.apiBaseUrl}/${endpoint}/`,
      withCredentials: true,
    });

    instance.interceptors.response.use(null, (error) => {
      const originalRequest: AxiosRequestConfig = error.config;

      if (
        error.response?.status === 401 &&
        originalRequest.url === `${environment.apiBaseUrl}/auth/token`
      ) {
        // If refreshing token failed, logout
        // TODO: Logout
        localStorage.removeItem('aboIdUser:loggedIn');
        return instance
          .post(`${environment.apiBaseUrl}/auth/logout`)
          .then((res) => {
            if (res.status === 201) {
              window.location.href = '/login';
              return;
            }
          })
          .catch((err) => {
            return Promise.reject(error);
          });
      }

      // If token is not valid, try to refresh token
      if (error.config && error.response && error.response?.status === 401) {
        return instance
          .get(`${environment.apiBaseUrl}/auth/token`)
          .then((res) => {
            if (res.status === 200) {
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            return Promise.reject(error);
          });
      }

      return Promise.reject(error);
    });

    this.httpClient = instance;
  }

  public async get<T = any>(endpoint?: string | number, data?: object) {
    const res = await this.httpClient.get(
      `${endpoint}${this.objectToQuery(data)}`
    );
    return res.data as T;
  }

  public async post<T = any>(endpoint?: string, data?: object) {
    const res = await this.httpClient.post(endpoint, data);
    return res.data as T;
  }

  public async put<T = any>(endpoint?: string, data?: object) {
    const res = await this.httpClient.put(endpoint, data);
    return res.data as T;
  }

  public async delete<T = any>(endpoint?: string, data?: object) {
    const res = await this.httpClient.delete(endpoint, data);
    return res.data as T;
  }

  public objectToQuery(obj) {
    if (!obj) {
      return '';
    }
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return '?' + str.join('&');
  }
}
