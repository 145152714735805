import { CreateGroupDto } from './../models/api/models/CreateGroupDto';
import { autoinject } from 'aurelia-framework';
import {
  AddGroupMemberDto,
  ProfileRes,
  RespondGroupInvitationDto,
  SuccessIdRes,
  SuccessRes,
  UserGroupDetailsRes,
  UserPendingGroupInvitation,
  UserUserGroupInvitation,
} from 'models/api';
import { UserSessionSimple } from './../models/api/models/UserSessionSimple';
import { ApiService } from './api-service';

@autoinject
export default class UserService extends ApiService {
  constructor() {
    super('users');
  }

  async getUser(): Promise<ProfileRes> {
    return super.get('profile');
  }

  async getUserForClient(
    clientSlug: string
  ): Promise<{ profile: ProfileRes; hasSubscription: boolean }> {
    return super.get(`profile-with-client/${clientSlug}`);
  }

  async getSessions(): Promise<UserSessionSimple[]> {
    return super.get('sessions');
  }

  async updateUser(data: any): Promise<ProfileRes> {
    return super.put('details', data);
  }

  async removeSession(sessionId: string): Promise<SuccessRes> {
    return super.delete(`sessions/${sessionId}`);
  }

  async acceptTerms(): Promise<SuccessRes> {
    return super.post('accept-terms');
  }

  async checkEmailExist(email: string): Promise<SuccessRes> {
    return super.get(`check-email-exist/user?email=${email}`);
  }

  async checkPhoneExist(phone: string): Promise<SuccessRes> {
    return super.get(`check-phone-exist/user?phone=${phone}`);
  }

  async getGroups() {
    return super.get('groups');
  }

  async getGroupInvitations(): Promise<UserUserGroupInvitation[]> {
    return super.get('group-invitations');
  }

  async getGroup(id: number): Promise<UserGroupDetailsRes> {
    return super.get(`groups/${id}`);
  }

  async getGroupPendingInvitations(
    id: number
  ): Promise<UserPendingGroupInvitation[]> {
    return super.get(`groups/${id}/invitations`);
  }

  async createGroup(dto: CreateGroupDto): Promise<SuccessIdRes> {
    return super.post('group', dto);
  }

  async updateGroup(id: number, dto: CreateGroupDto): Promise<SuccessIdRes> {
    return super.put(`group/${id}`, dto);
  }

  async deleteGroup(id: number): Promise<SuccessIdRes> {
    return super.delete(`group/${id}`);
  }

  async addGroupMember(
    id: number,
    dto: AddGroupMemberDto
  ): Promise<SuccessRes> {
    return super.post(`group/${id}/member`, dto);
  }

  async removeGroupMember(
    groupId: number,
    userId: number
  ): Promise<SuccessRes> {
    return super.delete(`group/${groupId}/member/${userId}`);
  }

  async respondGroupInivtation(
    id: number,
    dto: RespondGroupInvitationDto
  ): Promise<SuccessRes> {
    return super.put(`group-invitation/${id}/respond`, dto);
  }

  async deleteGroupInvitation(id: number): Promise<SuccessRes> {
    return super.delete(`group-invitation/${id}`);
  }

  async updateEmail(
    email: string,
    emailVerifyCode: string
  ): Promise<SuccessRes> {
    return super.put('update-email', { email, emailVerifyCode });
  }
}
