import { autoinject } from 'aurelia-framework';
import { NotificationsService } from './notifications-service';

@autoinject
export class ErrorService {
  constructor(private notificationsService: NotificationsService) {}

  public handleError(error) {
    console.error(error);

    // Multiple response messages
    if (error?.response?.data?.messages) {
      const message = error?.response?.data?.messages[0];
      return this.notificationsService.error('En feil har oppstått', message);
    } else if (Array.isArray(error?.response?.data?.message)) {
      const message = error?.response?.data?.message[0];
      return this.notificationsService.error('En feil har oppstått', message);
    } else if (error?.response?.data?.message) {
      const message = error?.response?.data?.message;
      return this.notificationsService.error('En feil har oppstått', message);
    }

    return this.notificationsService.error('En feil har oppstått', error);
  }

  getErrorMessage(errorCode: string) {
    switch (errorCode) {
      case '1000':
        return 'En feil har oppstått';
      case '1001':
        return 'Du må logge inn med din eksisterende konto og verifisere e-post for å koble til Vipps.';
      case '1002':
        return 'Det finnes flere brukere med samme innlogging, vennligst kontakt support.';
      default:
        return 'En feil har oppstått';
    }
  }
}
