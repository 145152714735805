import { ApiService } from './api-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import LocalService from './local-service';
import {
  AccessTokensDto,
  LoginClientDto,
  LoginClientResponse,
  SuccessRes,
} from 'models/api';

@autoinject
export default class AuthService extends ApiService {
  constructor(private router: Router, private localService: LocalService) {
    super('auth');
  }

  async getAccessTokens(
    authToken: string
  ): Promise<{ accessToken: string; refreshToken: string }> {
    return super.post('user/vipps-tokens', { authToken });
  }

  async loginClient(
    loginClientDto: LoginClientDto
  ): Promise<LoginClientResponse> {
    return super.post('user/client', loginClientDto);
  }

  loginPasswordless(email: string, clientSlug?: string) {
    return super.post('passwordless-link', { email, clientSlug });
  }

  loginCredentials(username: string, password: string) {
    return super.post('user/login', { username, password });
  }

  sendVerifyCode(email: string) {
    return super.post('user/send-verify-code', { email });
  }

  async logout(navigateToLogin: boolean = true) {
    this.localService.remove('authToken');
    this.localService.remove('userInfo', true);
    this.localService.remove('aboid.session');

    if (navigateToLogin) {
      this.router.navigateToRoute('login');
    }
  }

  async loginWithVipps(accessToken: string) {
    return super.get(`vipps/callback?access_token=${accessToken}`);
  }

  async setToken(token: string) {
    return localStorage.setItem('authToken', token);
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const res = await super.get('is-logged-in');
      return true;
    } catch (error) {
      return false;
    }
  }

  getAccessTokensWithPasswordless(
    authToken: string,
    email: string
  ): Promise<AccessTokensDto> {
    return super.post('user/passwordless-tokens', { authToken, email });
  }

  checkUserExists(username: string): Promise<boolean> {
    return super.post('user/exist', { username });
  }

  updatePassword(password: string): Promise<boolean> {
    return super.post('user/update-password', { password });
  }

  getResetPasswordLink(email: string): Promise<boolean> {
    return super.post('user/reset-password-link', { email });
  }

  verifyResetPasswordLink(token: string) {
    return super.get(`user/verify-reset-password-link?token=${token}`);
  }

  resetPassword(password: string, token: string): Promise<boolean> {
    return super.post('user/reset-password', { password, token });
  }
}
