import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import {
  ConfirmDangerModal,
  ConfirmDangerModalOptions,
} from 'components/global/modals/confirm-danger-modal';
import { AnimationsService } from './animations-service';

@autoinject
export class ModalService {
  constructor(private animationsService: AnimationsService, private dialogService: DialogService) {}

  confirmDanger(options: ConfirmDangerModalOptions): Promise<boolean> {
    // Default values
    options.cancelButtonText = options.cancelButtonText ?? 'Avbryt';

    return this.dialogService
      .open({ viewModel: ConfirmDangerModal, keyboard: true, model: options })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          return true;
        }
        return false;
      });
  }
}
